import {computed} from "vue";
import {type RouteLocationRaw, useRoute, useRouter} from "vue-router";

export function useActiveRoute(to?: RouteLocationRaw) {
  const route = useRoute();
  const router = useRouter();

  const resolvedPath = computed(() => {
    if (!to) {
      return null;
    }
    return router.resolve(to).href;
  });


  const isActive = computed(() => route.fullPath === resolvedPath.value);

  return {
    isActive,
  };
}
